import { useEffect, useState } from 'react';

interface IWindowDimensions {
  windowWidth: number | undefined;
  windowHeight: number | undefined;
}

interface IBreakPoints {
  'sm-': boolean | undefined;
  sm: boolean | undefined;
  'md-': boolean | undefined;
  md: boolean | undefined;
  'lg-': boolean | undefined;
  lg: boolean | undefined;
}

interface IUseWindowDimensions extends IWindowDimensions {
  breakPoints: IBreakPoints;
}

export const useWindowDimensions = (): IUseWindowDimensions => {
  const [windowDimensions, setWindowDimensions] = useState<IWindowDimensions>({
    windowWidth: undefined,
    windowHeight: undefined,
  });

  const [breakPoints, setBreakPoints] = useState<IBreakPoints>({
    'sm-': undefined,
    sm: undefined,
    'md-': undefined,
    md: undefined,
    'lg-': undefined,
    lg: undefined,
  });

  const breakPointSizes = {
    m: 0,
    sm: 640,
    md: 768,
    lg: 1024,
  };

  useEffect(() => {
    function handleResize(): void {
      setWindowDimensions({
        windowWidth: window.innerWidth,
        windowHeight: window.innerHeight,
      });
      setBreakPoints({
        'sm-': window.innerWidth < breakPointSizes.sm,
        sm:
          window.innerWidth >= breakPointSizes.sm &&
          window.innerWidth < breakPointSizes.md,
        'md-': window.innerWidth < breakPointSizes.md,
        md:
          window.innerWidth >= breakPointSizes.md &&
          window.innerWidth < breakPointSizes.lg,
        'lg-': window.innerWidth < breakPointSizes.lg,
        lg: window.innerWidth >= breakPointSizes.lg,
      });
    }
    handleResize();

    window.addEventListener('resize', handleResize);
    return (): void => window.removeEventListener('resize', handleResize);
  }, []);

  return { ...windowDimensions, breakPoints };
};
