import { MutableRefObject, useCallback, useEffect } from 'react';
import { useInViewport } from 'react-in-viewport';

const animationDelayClassNameList = [
  'animation-delay-0',
  'animation-delay-1',
  'animation-delay-2',
  'animation-delay-3',
  'animation-delay-4',
  'animation-delay-5',
  'animation-delay-6',
  'animation-delay-7',
  'animation-delay-8',
  'animation-delay-9',
  'animation-delay-10',
  'animation-delay-11',
  'animation-delay-12',
  'animation-delay-13',
  'animation-delay-14',
  'animation-delay-15',
  'animation-delay-16',
  'animation-delay-17',
  'animation-delay-18',
  'animation-delay-19',
  'animation-delay-20',
  'animation-delay-21',
  'animation-delay-22',
  'animation-delay-23',
  'animation-delay-24',
  'animation-delay-25',
  'animation-delay-26',
  'animation-delay-27',
  'animation-delay-28',
  'animation-delay-29',
  'animation-delay-30',
];

const animationDurationClassNameList = [
  'animation-duration-0',
  'animation-duration-1',
  'animation-duration-2',
  'animation-duration-3',
  'animation-duration-4',
  'animation-duration-5',
  'animation-duration-6',
  'animation-duration-7',
  'animation-duration-8',
  'animation-duration-9',
  'animation-duration-10',
  'animation-duration-11',
  'animation-duration-12',
  'animation-duration-13',
  'animation-duration-14',
  'animation-duration-15',
  'animation-duration-16',
  'animation-duration-17',
  'animation-duration-18',
  'animation-duration-19',
  'animation-duration-20',
  'animation-duration-21',
  'animation-duration-22',
  'animation-duration-23',
  'animation-duration-24',
  'animation-duration-25',
  'animation-duration-26',
  'animation-duration-27',
  'animation-duration-28',
  'animation-duration-29',
  'animation-duration-30',
];

export type IAnimationConfig = {
  delay?: number;
  duration?: number;
} & (
  | {
      type: 'slide-in' | 'slide-in-fade' | 'clip';
      from: 'left' | 'right' | 'bottom';
    }
  | {
      type: 'fade';
    }
);

interface IUseAnimation {
  containerRef: MutableRefObject<null | HTMLDivElement>;
}

export const setAnimationConfig = (config: IAnimationConfig) => {
  return encodeURIComponent(JSON.stringify(config));
};

export const getAnimationConfig = (
  config: string | undefined
): Required<IAnimationConfig> | null => {
  if (!config) return null;

  return JSON.parse(decodeURIComponent(config));
};

export const useAnimation = ({ containerRef }: IUseAnimation) => {
  const getAnimationClasses = useCallback((animation) => {
    if (!animation) return [];

    const classes: string[] = [];

    const { type, delay = 0, duration = 2 } = animation;

    classes.push(type);
    classes.push(animationDelayClassNameList[delay]);
    classes.push(animationDurationClassNameList[duration]);

    if (type === 'slide-in') {
      classes.push(`slide-in-from-${animation.from}`);
    }

    if (type === 'slide-in-fade') {
      classes.push(`slide-in-fade-from-${animation.from}`);
    }

    if (type === 'clip') {
      classes.push(`clip-from-${animation.from}`);
    }

    if (type === 'fade') {
      classes.push(`fade`);
    }

    return classes;
  }, []);

  const { inViewport } = useInViewport(
    containerRef,
    {},
    { disconnectOnLeave: false }
  );

  useEffect(() => {
    if (!window) {
      return;
    }

    if (containerRef?.current) {
      const animableElements: HTMLElement[] = Array.from(
        containerRef.current.querySelectorAll('[data-animation]')
      );

      animableElements.map((el) => {
        const config = getAnimationConfig(el?.dataset?.animation);

        if (config) {
          const classes = [`animable`, ...getAnimationClasses(config)];

          el.classList.add(...classes);

          if (inViewport) {
            const animateClasses = ['in-view'];
            el.classList.add(...animateClasses);
          }
        }
      });
    }
  }, [containerRef, getAnimationClasses, inViewport]);

  return null;
};
