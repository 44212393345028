import { useCallback, useEffect, useState } from 'react';
import { useWindowDimensions } from './useWindowDimensions';

interface IUseSlidesOffset {
  containerWidth: number; // container width without margins and paddings
  containerPadding: number;
}

export const useSlidesOffset = (
  { containerWidth, containerPadding }: IUseSlidesOffset = {
    containerWidth: 1366,
    containerPadding: 24,
  }
): number => {
  const [slidesOffset, setSlidesOffset] = useState<number>(0);
  const { windowWidth } = useWindowDimensions();
  const calculateSlidesOffset = useCallback(() => {
    if (windowWidth && containerWidth) {
      const slidesOffset =
        windowWidth > containerWidth
          ? (windowWidth - containerWidth) / 2 + containerPadding
          : containerPadding;

      setSlidesOffset(slidesOffset);
    }
  }, [containerWidth, containerPadding, windowWidth]);

  useEffect(() => {
    calculateSlidesOffset();
  }, [containerWidth, containerPadding, windowWidth, calculateSlidesOffset]);

  return slidesOffset;
};
