export const siteLanguage = process.env.NEXT_PUBLIC_SITE_LANG || 'en';

export const PAGES = {
  ABOUT_US: 'about-us',
  COMPANIES: 'companies',
  SOCIAL: 'social',
  CONTACT: 'contact',
};

export const routeMap: { [key: string]: { [key: string]: string } } = {
  en: {
    [PAGES.ABOUT_US]: 'about-us',
    [PAGES.COMPANIES]: 'companies',
    [PAGES.SOCIAL]: 'social',
    [PAGES.CONTACT]: 'contact',
  },
  hu: {
    [PAGES.ABOUT_US]: 'rolunk',
    [PAGES.COMPANIES]: 'cegeink',
    [PAGES.SOCIAL]: 'felelossegvallalas',
    [PAGES.CONTACT]: 'kapcsolat',
  },
};

export const getLanguageSpecificRoute = (route: string) => {
  if (route === 'news' && siteLanguage === 'hu') {
    return 'hirek';
  }

  return routeMap[siteLanguage][route] || route;
};
