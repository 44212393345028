export const getLinkTarget = (link: string) => {
  const isExternalRegExp = new RegExp('^(http|https)://', 'g');
  return isExternalRegExp.test(link) ? '_blank' : '_self';
};

export const getStaticUrl = (url: string, addCDNUrl = false) => {
  return `${addCDNUrl ? process.env.NEXT_PUBLIC_STATIC_URL : ''}${url}`;
};

export const trimString = (text: string, limit = 80, dots = true) => {
  let trimmedString = text.substring(0, limit);

  trimmedString = trimmedString.substring(
    0,
    Math.min(trimmedString.length, trimmedString.lastIndexOf(' '))
  );

  if (dots) {
    trimmedString += '...';
  }

  return trimmedString;
};

interface ISendEvent {
  eventName: string;
  detail?: { [key: string]: string };
}

export const sendEvent = ({ eventName, detail }: ISendEvent) => {
  const event = new CustomEvent(eventName, { detail });
  window.dispatchEvent(event);
};

export const addSiteUrlToPath = (path: string) => {
  return `${process.env.NEXT_PUBLIC_SITE_URL}${path}`;
};

export const getStaticPageRevalidationTime = (): number => {
  const env = process.env.NEXT_REVALIDATE_INTERVAL;

  if (env) {
    switch (typeof env) {
      case 'string':
        return +env;
      case 'number':
        return env;
      default:
        return 120;
    }
  }

  return 120;
};
