import { forwardRef, PropsWithChildren, ReactNode } from 'react';

interface IContainer {
  children: ReactNode;
  className?: string;
  fullWidth?: boolean;
  maxWidth?: string;
}

// eslint-disable-next-line react/display-name
export const Container = forwardRef<
  HTMLDivElement,
  PropsWithChildren<IContainer>
>(
  (
    {
      children,
      className = '',
      fullWidth = false,
      maxWidth = 'max-w-[1366px]',
    },
    ref
  ): JSX.Element => {
    return (
      <section
        className={`${
          !fullWidth ? `${maxWidth} px-6 mx-auto` : ''
        } ${className}`}
        ref={ref}
      >
        {children}
      </section>
    );
  }
);
