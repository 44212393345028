import Link from 'next/link';
import { FC } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useHeaderShrink, useWindowDimensions } from '../hooks';
import { SocialLink } from './SocialLink';
import DHIcon from '/public/static/DoclerHoldingLogo.svg';
import FacebookIcon from '/public/static/Facebook.svg';
import CloseIcon from '/public/static/Close.svg';
import HamburgerIcon from '/public/static/Hamburger.svg';
import TwitterIcon from '/public/static/Twitter.svg';
import InstagramIcon from '/public/static/Instagram.svg';
import { useRouter } from 'next/router';
import { ListItem } from './ListItem';
import { Container } from './Container';

export interface Route {
  id: string;
  attributes: {
    label: string;
    value: string;
    external: boolean;
  };
}

interface IHeaderProps {
  routes: Route[];
}

const LanguageSelector = () => {
  const onClick = useCallback((lang) => {
    const tld = lang === 'en' ? '.com' : '.hu';
    const domain = 'doclerholding';
    location.href = `https://${domain}${tld}`;
  }, []);

  return (
    <div
      className={`flex py-6 pl-8 pr-6 uppercase text-[#FF9999] text-xl gap-10 lg:text-base lg:gap-2 lg:pl-4 xl:pl-6`}
    >
      <span
        onClick={() => onClick('en')}
        className={`tap-highlight-transparent cursor-pointer ${
          process.env.NEXT_PUBLIC_SITE_LANG === 'en' && 'text-white'
        }`}
      >
        EN
      </span>

      <span
        onClick={() => onClick('hu')}
        className={`tap-highlight-transparent cursor-pointer ${
          process.env.NEXT_PUBLIC_SITE_LANG === 'hu' && 'text-white'
        }`}
      >
        HU
      </span>
    </div>
  );
};

export const Header: FC<IHeaderProps> = (props) => {
  const { routes = [] } = props;
  const { asPath } = useRouter();
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const { isHeaderShrinked } = useHeaderShrink();
  const {
    breakPoints: { 'lg-': isMobile },
  } = useWindowDimensions();

  const onOpenMenu = useCallback(() => {
    if (!menuOpen) {
      document.body.style.overflow = 'hidden';
      setMenuOpen(true);
    }
  }, [menuOpen]);

  const onCloseMenu = useCallback(() => {
    document.body.style.overflow = 'auto';
    setMenuOpen(false);
  }, []);

  useEffect(() => {
    onCloseMenu();
  }, [asPath, onCloseMenu]);

  if (isMobile === undefined) return null;

  return (
    <header
      className={`fixed flex justify-center top-0 left-0 font-barlow bg-[#C00000] transition-[height] duration-300 w-full z-20 h-header`}
    >
      <Container
        fullWidth
        className="flex w-full max-w-[1366px] justify-between items-center"
      >
        <Link href="/">
          <a
            className={`flex h-full items-center text-white px-8 lg-:px-4 tap-highlight-transparent`}
          >
            <DHIcon width={78} height={24} />
          </a>
        </Link>
        {!!routes?.length && (
          <div className="relative flex uppercase text-base font-normal tracking-[2px] text-white">
            {isMobile ? (
              <HamburgerIcon
                width={24}
                height={24}
                className="self-end my-[16px] mx-[24px]"
                onClick={onOpenMenu}
              />
            ) : (
              <div className="flex items-center">
                {routes
                  .filter(({ attributes: { value } }) => value !== '/')
                  .map(({ attributes: { label, value } }) => (
                    <ListItem
                      key={label}
                      label={label}
                      value={value}
                      className={`px-[11px] xl:px-6 ${
                        isHeaderShrinked
                          ? 'after:bottom-[24px]'
                          : 'after:bottom-[16px]'
                      } ${
                        asPath === value
                          ? 'after:w-[calc(100%-22px)] xl:after:w-[calc(100%-48px)]'
                          : 'after:w-0 hover:after:w-[25px]'
                      }`}
                    />
                  ))}
                <LanguageSelector />
              </div>
            )}
          </div>
        )}
      </Container>
      {isMobile && (
        <div
          className={`fixed opacity-0 bg-header bg-bottom bg-contain bg-no-repeat top-0 left-0 flex flex-col items-center justify-between h-full w-full overflow-y-auto transition-opacity duration-500 ${
            menuOpen ? '!opacity-100' : 'pointer-events-none'
          }`}
        >
          <div className="flex w-full items-center flex-col">
            <div className="flex w-full justify-between">
              <LanguageSelector />
              <CloseIcon
                width={27}
                height={27}
                className="self-end mt-[22px] mb-14 mx-[30px]"
                onClick={onCloseMenu}
              />
            </div>
            <div className="flex flex-col items-center uppercase font-normal tracking-[2px] leading-8 text-white text-[22px]">
              {routes.map(({ attributes: { label, value } }) => (
                <ListItem
                  key={label}
                  label={label}
                  value={value}
                  className={`p-4 after:bottom-[13px] tap-highlight-transparent ${
                    asPath === value ? 'after:w-[calc(100%-32px)]' : 'after:w-0'
                  }`}
                />
              ))}
            </div>
          </div>
          <div className="flex gap-x-12 justify-center w-full py-10">
            <SocialLink
              icon={FacebookIcon}
              url="https://facebook.com/DoclerHolding"
            />
            <SocialLink
              icon={TwitterIcon}
              url="https://twitter.com/doclerholdinglu"
            />
            <SocialLink
              icon={InstagramIcon}
              url="https://instagram.com/doclerholdingbud"
            />
          </div>
        </div>
      )}
    </header>
  );
};
