import { useCallback, useEffect, useRef, useState } from 'react';
import { sendEvent } from '../helpers';
import { useWindowDimensions } from '../hooks';
import { useRegisterWindowEvent } from '../hooks';
import CloseIcon from '/public/static/Close.svg';

export const VideoOverlay = () => {
  const {
    breakPoints: { 'lg-': isMobile },
  } = useWindowDimensions();
  const videoRef = useRef<HTMLVideoElement>(null);
  const videoContainerRef = useRef<HTMLDivElement>(null);
  const [videoDetails, setVideoDetails] = useState<{
    url: '';
    thumbnail: '';
  } | null>();

  const closeVideo = useCallback(() => {
    videoRef.current?.pause();
    setVideoDetails(null);
  }, [videoRef]);

  const onPlayVideo = useCallback((event) => {
    setVideoDetails(event.detail);
  }, []);

  const onClose = useCallback(() => {
    closeVideo();
  }, [closeVideo]);

  const onKeyUp = useCallback(
    (event) => {
      if (event.keyCode === 27) {
        closeVideo();
      }
    },
    [closeVideo]
  );

  const onOrientationChange = useCallback(() => {
    if (!!window.orientation && videoContainerRef.current?.requestFullscreen) {
      videoContainerRef.current
        ?.requestFullscreen()
        .catch((error) => console.warn(error));
    } else if (document.fullscreenElement) {
      document.exitFullscreen();
    }
  }, [videoContainerRef]);

  useRegisterWindowEvent('openVideoOverlay', onPlayVideo);
  useRegisterWindowEvent('keyup', onKeyUp);
  useRegisterWindowEvent('orientationchange', onOrientationChange);

  useEffect(() => {
    if (videoDetails) {
      document.body.style.overflowY = 'hidden';
      if (isMobile) {
        onOrientationChange();
      }
    } else {
      document.body.style.overflowY = 'auto';
      sendEvent({ eventName: 'closeVideoOverlay' });
    }
  }, [videoDetails]);

  if (!videoDetails) return null;

  return (
    <div
      ref={videoContainerRef}
      className={`fixed flex w-full m-auto h-full lg:h-[calc(100%-var(--headerHeight))] transition-opacity duration-500 ${
        videoDetails
          ? 'opacity-100 pointer-events-auto'
          : 'opacity-0 pointer-events-none'
      } bottom-0 left-0 z-20`}
    >
      <div
        onClick={onClose}
        className="absolute w-full h-full bg-[rgba(0,0,0,.8)] lg:bg-[rgba(0,0,0,.4)]"
      ></div>
      <div className="relative m-auto max-h-full lg:max-w-[80%]">
        <div
          onClick={onClose}
          className="absolute top-0 right-0 p-2 md:p-4 z-10 cursor-pointer bg-closeGradient"
        >
          <CloseIcon width={15} height={15} />
        </div>
        <video
          ref={videoRef}
          src={videoDetails?.url}
          poster={videoDetails?.thumbnail}
          autoPlay
          controls
          controlsList={`${isMobile && 'nofullscreen'} nodownload`}
          onEnded={onClose}
        />
      </div>
    </div>
  );
};
