import { useCallback, useEffect, useRef, useState } from 'react';
import { useWindowDimensions } from './useWindowDimensions';

export const useContinuousCarousel = ({
  wrapper,
  group,
}: {
  wrapper: HTMLElement | HTMLDivElement | null;
  group: HTMLElement | HTMLDivElement | null;
}) => {
  const timerRef = useRef<ReturnType<typeof setInterval> | null>(null);
  const [transformX, setTransformX] = useState(0);
  const [groupWidth, setGroupWidth] = useState(0);
  const { windowWidth } = useWindowDimensions();
  const [paused, setPaused] = useState<boolean>(false);

  const play = useCallback(() => {
    setPaused(false);
  }, [setPaused]);

  const pause = useCallback(() => {
    setPaused(true);
  }, [setPaused]);

  useEffect(() => {
    if (group) {
      const clonedGroup = group.cloneNode(true);
      group.after(clonedGroup);
    }
  }, [group]);

  useEffect(() => {
    if (!timerRef.current && !paused) {
      timerRef.current = setInterval(() => {
        setTransformX((prev) => prev - 1);
      }, 20);
    } else if (timerRef.current && paused) {
      clearInterval(timerRef.current);
      timerRef.current = null;
    }

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
        timerRef.current = null;
      }
    };
  }, [timerRef, setTransformX, paused]);

  useEffect(() => {
    if (wrapper) {
      wrapper.style.transform = `translate3d(${transformX}px, 0, 0)`;
    }
  }, [wrapper, transformX]);

  useEffect(() => {
    if (transformX < -groupWidth) {
      setTransformX(0);
    }
  }, [transformX, groupWidth, setTransformX]);

  useEffect(() => {
    if (group) {
      setGroupWidth(group.clientWidth);
    }
  }, [group, setGroupWidth, windowWidth]);

  return { play, pause, paused };
};
