import { useEffect } from 'react';

export const useRegisterWindowEvent = (
  name: string,
  callback: ({ ...props }) => void,
  depencencies: [() => void | boolean] | [] = []
) => {
  useEffect(() => {
    window.addEventListener(name, callback);
    return () => window.removeEventListener(name, callback);
  }, [...depencencies]);
};
