import { useCookieConsent } from '../hooks';

import { CookieConsentContextType } from '../hooks/useCookieConsent';
import { CookieConsentValue } from '../hooks/useCookieConsent';
import React, { useEffect } from 'react';
import { ApiCookieConsentCookieConsent } from '../types/strapi/contentTypes';
import { getStaticUrl } from '../helpers';

export interface CookieConsentModalProps {
  cookieConsentData: ApiCookieConsentCookieConsent;
}

function Button(props: React.HTMLAttributes<HTMLButtonElement>) {
  return (
    <button
      {...props}
      className="border-gray-200 border-solid border text-[#C00000] hover:text-white hover:bg-[#C00000] rounded py-3 w-full"
    />
  );
}

export const CookieConsentModal = ({
  cookieConsentData,
}: CookieConsentModalProps) => {
  const { cookieConsentValue, acceptCookieConsent, declineCookieConsent } =
    useCookieConsent() as CookieConsentContextType;

  useEffect(() => {
    if (cookieConsentValue === CookieConsentValue.NOT_DECIDED) {
      document.body.style.position = 'fixed';
    } else {
      if (document.body.style.position === 'fixed') {
        document.body.style.position = 'static';
      }
    }
  }, [cookieConsentValue]);

  if (cookieConsentValue === CookieConsentValue.NOT_DECIDED)
    return (
      <div className="fixed top-0 left-0 flex bg-black bg-opacity-60 justify-center items-end lg:items-start lg:pt-10 w-full h-full z-50">
        <div className="transition ease-in-out delay-150 w-full flex justify-center lg:max-w-lg rounded-t-3xl lg:rounded bg-white p-8 ">
          <div className="w-80 lg:w-full flex flex-col items-center gap-8">
            <img
              className="w-44"
              alt={'Doclerholding logo'}
              src={getStaticUrl(
                cookieConsentData.attributes.logo.data.attributes.url,
                true
              )}
            />
            <h2 className="font-bold uppercase w-full text-left text-base lg:text-xl m-0">
              {cookieConsentData.attributes.title}
            </h2>
            <p className="text-sm">
              {cookieConsentData.attributes.text}{' '}
              <a
                className={'text-[#C00000]'}
                target={'_blank'}
                href={getStaticUrl(
                  cookieConsentData.attributes.cookie_policy.data.attributes
                    .url,
                  true
                )}
                rel="noreferrer"
              >
                {cookieConsentData.attributes.cookie_policy_link_title}
              </a>
            </p>
            <div className="w-full flex flex-col gap-2">
              <Button onClick={acceptCookieConsent}>
                {cookieConsentData.attributes.accept_label}
              </Button>
              <Button onClick={declineCookieConsent}>
                {cookieConsentData.attributes.reject_label}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );

  return null;
};
