import Link from 'next/link';
import { FC } from 'react';
import { getLinkTarget } from '../helpers';
import { getLanguageSpecificRoute } from '../helpers/routing';

export interface IListItem {
  label: string;
  value: string;
  className?: string;
}

export const ListItem: FC<IListItem> = ({ label, value, className }) => {
  const isExternal = getLinkTarget(value);
  const routeWithoutSlash = value?.replace(/^\/+/, '');

  return (
    <p className="relative flex h-full items-center w-fit">
      <Link
        href={
          value === '/'
            ? value
            : routeWithoutSlash === getLanguageSpecificRoute('news')
            ? '/[news]/[[...page]]'
            : '/[...slug]'
        }
        as={
          !value || value === ''
            ? `/${getLanguageSpecificRoute('companies')}`
            : value
        }
      >
        <a
          target={isExternal}
          className={`flex h-full items-center text-center menu-item-underline ${className}`}
        >
          {label}
        </a>
      </Link>
    </p>
  );
};
