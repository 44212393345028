import '../styles/globals.css';
import { useRouter } from 'next/router';
import type { AppProps } from 'next/app';
import { JSONSchema4Object } from 'json-schema';
import Head from 'next/head';
import Script from 'next/script';
import Layout from '../components/Layout';
import particlesConfig from '../public/static/particlesjs-config.json';
import { addSiteUrlToPath } from '../helpers';
import { GoogleAnalytics } from '../components/GoogleAnalyticsScripts';
import { CookieConsentProvider } from '../hooks/useCookieConsent';
import { CookieConsentModal } from '../components/CookieConsentModal';

declare function particlesJS(
  elementId: string,
  jsonConfig: JSONSchema4Object
): void;

function MyApp({ Component, pageProps }: AppProps) {
  const { asPath } = useRouter();
  const { routes, footer, head = {}, cookieConsentData } = pageProps;
  const {
    description = 'Docler Holding, IT company headquartered in Luxembourg',
    title = 'Docler Holding',
    image = {
      url: addSiteUrlToPath('/static/DoclerHolding.jpg'),
      width: 1920,
      height: 1280,
    },
  } = head;
  const url = addSiteUrlToPath(asPath);
  const textTitle = title?.replace(/\*red\*/gi, '');
  const textDescription = description?.replace(/\*red\*/gi, '');

  return (
    <>
      <CookieConsentProvider>
        <CookieConsentModal cookieConsentData={cookieConsentData} />
        <GoogleAnalytics />
        <Head>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=no, viewport-fit=cover"
          />
          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href="/static/favicon/apple-touch-icon.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href="/static/favicon/favicon-32x32.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href="/static/favicon/favicon-16x16.png"
          />
          <link rel="manifest" href="/static/favicon/site.webmanifest" />
          <meta
            property="og:site_name"
            content="Doclerholding"
            key="ogsitename"
          />
          {textDescription && (
            <>
              <meta name="description" content={textDescription} />
              <meta
                property="og:description"
                content={textDescription}
                key="ogdesc"
              />
            </>
          )}
          <meta property="og:url" content={url} key="ogurl" />
          {textTitle && (
            <>
              <title>{textTitle}</title>
              <meta property="og:title" content={textTitle} key="ogtitle" />
            </>
          )}
          {image?.url && (
            <>
              <meta property="og:image" content={image.url} key="ogimage" />
              <meta
                property="og:image:width"
                content={image.width}
                key="ogimagewidth"
              />
              <meta
                property="og:image:height"
                content={image.height}
                key="ogimageheight"
              />
            </>
          )}
          <meta
            name="twitter:card"
            content="summary_large_image"
            key="twcard"
          />
          <meta
            name="twitter:creator"
            content="@DoclerHoldingLU"
            key="twcreator"
          />
          <meta name="twitter:site" content="@DoclerHoldingLU" key="twsite" />
        </Head>
        <Layout routes={routes} footer={footer}>
          <noscript>
            <iframe
              src={`https://www.googletagmanager.com/ns.html?id=${process.env.NEXT_PUBLIC_GTM_ID}`}
              height="0"
              width="0"
              style={{ display: 'none', visibility: 'hidden' }}
            />
          </noscript>
          <Component {...pageProps} />
        </Layout>
        <Script
          src="/static/particles.min.js"
          onLoad={() => {
            if (document.getElementById('particles-js')) {
              particlesJS('particles-js', particlesConfig);
            }
          }}
        />
      </CookieConsentProvider>
    </>
  );
}

export default MyApp;
