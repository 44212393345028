import Script from 'next/script';
import { useCookieConsent } from '../hooks';
import {
  CookieConsentContextType,
  CookieConsentValue,
} from '../hooks/useCookieConsent';

export const GoogleAnalytics = () => {
  const { cookieConsentValue } = useCookieConsent() as CookieConsentContextType;

  if (cookieConsentValue === CookieConsentValue.ACCEPTED)
    return (
      <>
        <Script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-DNJN1PF3CS"
        ></Script>
        <Script id="ga-script" strategy="afterInteractive">
          {`window.dataLayer = window.dataLayer || [];
        function gtag() {window.dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${process.env.NEXT_PUBLIC_GTM_ID}');`}
        </Script>
      </>
    );

  return null;
};
