import { ReactNode } from 'react';
import { Footer, IFooterDetails } from './Footer';
import { Header } from './Header';
import { VideoOverlay } from './VideoOverlay';

interface Props {
  children: ReactNode;
  routes: [];
  footer: IFooterDetails;
}

export default function layout(props: Props) {
  const { routes, footer } = props;

  return (
    <div className="font-barlow transition-[padding] duration-300 pt-header overflow-x-hidden">
      {!!routes?.length && (
        <div
          id="particles-js"
          className="z-[-1] absolute w-full h-full top-96 left-0"
        />
      )}
      <Header routes={routes} />
      <main>{props.children}</main>
      {footer && <Footer routes={routes} footer={footer} />}
      <VideoOverlay />
    </div>
  );
}
