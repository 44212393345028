import { useCallback, useState } from "react";
import { useRegisterWindowEvent, useWindowDimensions } from "../hooks";

export const useHeaderShrink = () => {
  const [shrinkedHeader, setShrinkedHeader] = useState<boolean>(false);
  const {
    breakPoints: { 'lg-': isMobile },
  } = useWindowDimensions();


  const onHeaderShrink = useCallback(() => {
    if (isMobile) {
      return
    }

    const headerHeight = parseInt(
      window
        .getComputedStyle(document.documentElement)
        .getPropertyValue('--headerHeight')
    );

    if (window.scrollY > headerHeight) {
      if (!shrinkedHeader) {
        setShrinkedHeader(true);
        document.documentElement.style.setProperty('--headerHeight', '48px')
      }
    } else {
      if (shrinkedHeader) {
        setShrinkedHeader(false);
        document.documentElement.style.removeProperty('--headerHeight')
      }
    }
  }, [isMobile, setShrinkedHeader, shrinkedHeader]);

  useRegisterWindowEvent('scroll', onHeaderShrink, [onHeaderShrink]);

  return { isHeaderShrinked: shrinkedHeader };
};