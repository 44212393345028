import Link from 'next/link';
import { FC } from 'react';

interface ISocialLink {
  icon: React.ElementType;
  url: string;
}

export const SocialLink: FC<ISocialLink> = ({ icon: Icon, url }) => (
  <Link href={url}>
    <a target="_blank">
      <Icon className="fill-[#fff] w-[40px] h-[40px] lg:w-[24px] lg:h-[24px] " />
    </a>
  </Link>
);
