import { useEffect, useState } from 'react';

export const useShareUrl = () => {
  const [url, setUrl] = useState('');

  useEffect(() => {
    setUrl(window.location.href);
  }, []);

  return url;
};
