import React, { useCallback, useContext, useEffect, useState } from 'react';

export enum CookieConsentValue {
  ACCEPTED = 'ACCEPTED',
  DECLINED = 'DECLINED',
  NOT_DECIDED = 'NOT_DECIDED',
  LOADING = 'LOADING',
}

export interface CookieConsentContextType {
  cookieConsentValue: CookieConsentValue;
  acceptCookieConsent: () => void;
  declineCookieConsent: () => void;
}

const CookieConsentContext =
  React.createContext<CookieConsentContextType | null>(null);

export const useCookieConsent = () => {
  return useContext(CookieConsentContext);
};

export const CookieConsentProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [cookieConsentValue, setCookieConsentAccepted] =
    useState<CookieConsentValue>(CookieConsentValue.LOADING);

  const getIsCookieConsentAccepted = useCallback((): CookieConsentValue => {
    try {
      /** cca = Cookie Consent Accepted **/
      return (localStorage.getItem('cca') ??
        'NOT_DECIDED') as CookieConsentValue;
    } catch (e) {
      console.error('cannot read cookie consent value');
      return CookieConsentValue.DECLINED;
    }
  }, []);

  const writeCookieConsentValue = (value: CookieConsentValue) => {
    try {
      /** cca = Cookie Consent Accepted **/
      localStorage.setItem('cca', value as string);
    } catch (e) {
      console.error('cannot write cookie consent value');
    }
  };

  const acceptCookieConsent = () => {
    setCookieConsentAccepted(CookieConsentValue.ACCEPTED);
    writeCookieConsentValue(CookieConsentValue.ACCEPTED);
  };

  const declineCookieConsent = () => {
    setCookieConsentAccepted(CookieConsentValue.DECLINED);
    writeCookieConsentValue(CookieConsentValue.DECLINED);
  };

  useEffect(() => {
    setCookieConsentAccepted(getIsCookieConsentAccepted());
  }, [getIsCookieConsentAccepted]);

  return (
    <CookieConsentContext.Provider
      value={{
        cookieConsentValue,
        acceptCookieConsent,
        declineCookieConsent,
      }}
    >
      {children}
    </CookieConsentContext.Provider>
  );
};
